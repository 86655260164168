import axios from "axios";
import { config } from "./config";

/**
 * The global axios client used to make requests to the server.
 */
export const httpClient = axios.create({
  // send auth cookies with every request
  withCredentials: true,

  baseURL: config.serverBaseUrl,

  headers: { "Content-Type": "application/json" },

  // Both Axios and Laravel rely on the “XSRF-TOKEN” cookie to protect from CSRF attacks.
  //  We use a different method to protect ourselves, so we force Axios to check a non-existent cookie from its config.
  xsrfCookieName: "XSRF-TOKEN-NEXTGEN",
});
